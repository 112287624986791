<template>
  <div>
    <load-profile></load-profile>
    <!-- Table Container Card -->
    <b-card>
      <validation-observer ref="editForm" #default="{ invalid }">
        <!-- form -->
        <n-form-confirmation
          key="editForm"
          @submit="submit"
          :form="$refs.editForm"
          :disabled="invalid"
        >
          <n-input :fields="fields" v-model="data" :initValue="initData">
          </n-input>
          <b-row>
            <b-col cols="12" class="text-right">
              <n-button-loading
                type="submit"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mt-1 mr-1"
                :loading="loading"
                :disabled="invalid"
                v-if="$can('update', 'store')"
              >
                {{ $t('button.saveChanges') }}
              </n-button-loading>
              <n-button-loading
                type="button"
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                :loading="loading"
                class="mt-1"
                @submit="back"
              >
                {{ $t('button.back') }}
              </n-button-loading>
            </b-col>
          </b-row>
        </n-form-confirmation>
      </validation-observer>
    </b-card>
  </div>
</template>

<script>
import {
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BCardText,
  BLink,
  BFormRadioGroup,
  BTab,
  BTabs,
  BFormTextarea,
} from 'bootstrap-vue'
import Repository from '@/repositories/RepositoryFactory'
import Ripple from 'vue-ripple-directive'
import NButtonLoading from '@/components/NButtonLoading'
import NFormConfirmation from '@/components/NFormConfirmation'
import NInput from '@/components/NInput'
import formInput from './formInput'
import { required } from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

const StoreRepository = Repository.get('store')

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BCardText,
    BLink,
    BFormRadioGroup,
    NButtonLoading,
    NFormConfirmation,
    NInput,
    BTab,
    BTabs,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {
        name_en: null,
        name_km: null,
        isEnable: 1,
      },
      initData: {},
      loading: false,
      required,
    }
  },
  mounted() {
    this.show()
  },
  methods: {
    show() {
      StoreRepository.show(this.$route.params.id)
        .then((response) => {
          response.data.data.day = response.data.data.day.split('-')
          openTime = response.data.data.openTime.split(':')
          response.data.data.openTime = openTime[0] + ':' + openTime[1]
          closeTime = response.data.data.closeTime.split(':')
          response.data.data.closeTime = closeTime[0] + ':' + closeTime[1]

          this.initData = {
            ...response.data.data,
          }

          this.data = {
            ...response.data.data,
          }
        })
        .catch()
        .then(() => {})
    },
    submit() {
      this.$refs.editForm.validate().then((success) => {
        if (success) {
          this.loading = true
          StoreRepository.update(this.$route.params.id, {
            ...this.data,
            day: this.data.day.join('-'),
          })
            .then((response) => {
              this.$router.push({
                name: 'view-store',
                params: { id: response.data.data.id },
              })
            })
            .catch((error) => {
              if (error.response?.status == 422) {
                this.$refs.editForm.setErrors(error.response?.data?.message)
              }
            })
            .then(() => {
              this.loading = false
            })
        }
      })
    },
    back() {
      this.$router.back()
    },
  },
  setup() {
    const fields = [...formInput]

    return { fields }
  },
}
</script>
