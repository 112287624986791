export default [
  {
    key: 'day',
    label: 'field.day',
    rules: 'required',
    type: 'NMultiSelection',
    options: [
      { id: 1, name: 'Monday' },
      { id: 2, name: 'Tuesday' },
      { id: 3, name: 'Wednesday' },
      { id: 4, name: 'Thursday' },
      { id: 5, name: 'Friday' },
      { id: 6, name: 'Saturday' },
      { id: 7, name: 'Sunday' },
    ],
    selectionKey: 'name',
    selectionLabel: 'name',
    cols: 12,
  },
  {
    key: 'openTime',
    label: 'field.open_time',
    rules: 'required',
    type: 'time',
    cols: 12,
  },
  {
    key: 'closeTime',
    label: 'field.close_time',
    rules: 'required',
    type: 'time',
    cols: 12,
  },
]
